import { useContext, useEffect } from "react";
import { useMap, CircleMarker, Popup } from "react-leaflet";

import { UserContext } from "../../contexts/UserContext/UserContext";


const splitUrls = (urls) => {

  const urlArray = urls.split(",");
  const links = urlArray.map((url) => {

    const splitUrl = url.split("/"); 
    const nameURL = splitUrl[splitUrl.length - 2];

    if (nameURL != null) {

      const title = nameURL
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
      
      return `<a href="${url}" target="_blank">${title}</a>`;

    } else {
      return null;
    }

  });
  return links.join(" | ");
};


const UpdateLocation = (props) => {

  const obj_map = useMap();

  let { updatePosition } = useContext(UserContext);
  let { updateIndex } = useContext(UserContext);

  const { pointsOfInterest } = props;
  const poi_data = pointsOfInterest.features[updateIndex];


  let poiName = '',
      poiDescription = '',
      poiImages = '',
      poiLinks = '',
      poiTextAttribution = '',
      poiImageAttribution = '',
      poiLinksHTML = '';


  if (updateIndex != null) {

    poiName = poi_data.properties.Name;
    poiDescription = poi_data.properties.Description_full;
    poiImages = poi_data.properties.Images;
    poiLinks = poi_data.properties.Links;
    poiTextAttribution = poi_data.properties["Text Attribution"];
    poiImageAttribution = poi_data.properties["Image Attribution"];
    
    poiLinksHTML = splitUrls(poiLinks);

  }

  useEffect(() => {

      if (updatePosition != null) {

        obj_map.flyTo(updatePosition, obj_map.getZoom());

      }

    }, [updatePosition]);

    return updatePosition === null ? null : (
      <div>
        <CircleMarker center={updatePosition} >
          <Popup>
            <h3>{poiName}</h3>
            <div style={{
                maxHeight: '150px',
                overflow: 'auto',
                borderStyle: 'solid',
                borderWidth: '1px 0',
                borderColor: 'lightgrey'
            }}>
              {poiDescription}
            </div>
            <div style={{marginTop: '15px'}}>
              <div style={{marginBottom: '5px'}}>{poiTextAttribution}</div>
              <div style={{
                overflow: 'auto',
                maxHeight: '225px',
                borderStyle: 'solid',
                borderWidth: '1px 0',
                borderColor: 'lightgrey'
              }}>
                <img src={poiImages} alt={`${poiName} Scenic View`} style={{width: '100%'}}></img>
              </div>
              <div style={{marginTop: '5px'}}>{poiImageAttribution}</div>
              <div style={{marginTop: '5px'}}><b>From the SC Encyclopedia:</b></div>
              <div dangerouslySetInnerHTML={{ __html: poiLinksHTML }} />
            </div>
            <div style={{marginTop: '10px'}}>
              <a href={'https://maps.google.com/?q=' + updatePosition} target="_blank" rel="noreferrer" >View on Google Maps</a>
            </div>
          </Popup>
        </CircleMarker>
      </div>
    );

}

export default UpdateLocation;