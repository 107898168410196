import React, { useState, useContext } from "react";
import { UserContext } from "../../contexts/UserContext/UserContext";
import "./SearchFeature.css";


const SearchFeature = ({ pointsOfInterest, isOpen, isDisplayOpen }) => {

  const [filteredPOIs, setFilteredPOIs] = useState(pointsOfInterest.features);
  const allPOIs = pointsOfInterest.features;

  let { setPosition, saveUpdatePosition, saveUpdateIndex } = useContext(UserContext);


  const handleSearchChange = (e) => {
    const searchString = e.target.value.toLowerCase();
  
    const filteredList = allPOIs.reduce((acc, poi, index) => {
      const lowerCaseName = poi.properties.Name.toLowerCase();
      const lowerCaseDesc = poi.properties.Description_full.toLowerCase();
      if (
        lowerCaseName.includes(searchString) ||
        lowerCaseDesc.includes(searchString)
      ) {
        acc.push({ ...poi, index });
      }
      return acc;
    }, []);
  
    setFilteredPOIs(filteredList);
  };

  const sidebarContainer = document.querySelector(".sidebar-container");
  const sidebarWindow = document.querySelector(".sidebar-window");

  const handleResultClick = (e) => {

    const str = e.currentTarget.id;
    const index = e.currentTarget.getAttribute('index') || e.currentTarget.getAttribute('idx');
    const coor = str.split(",");
    const latLng = [parseFloat(coor[1]), parseFloat(coor[0])];

    setPosition(latLng);
    saveUpdatePosition(latLng);
    saveUpdateIndex(index);
    
    sidebarContainer.classList.add("hidden");
    sidebarWindow.classList.add("hidden");

  };

  return (
    <div
      className="container"
      style={{ display: isOpen && isDisplayOpen ? "block" : "none" }}
    >
      <h1>Search Feature</h1>
      <input
        className="search-input"
        type="text"
        placeholder="Search..."
        onChange={(e) => handleSearchChange(e)}
      ></input>
      <div className="results">
        {filteredPOIs.map((poi, idx) => {

          const poi_index = poi.index || idx;

          return (
            <div
              className="results-item"
              key={idx}
              index={poi_index}
              id={poi.geometry.coordinates}
              onClick={(e) => handleResultClick(e)}
            >
              <div className="results-item-column results-item-name">
                <h3>{poi.properties.Name}</h3>
              </div>
              <div className="results-item-column results-item-desc">
                <p>{poi.properties.Description_full}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchFeature;