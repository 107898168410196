/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import MainMap from "./Components/MainMap/MainMap"; // DEV NOTES | Updated Path | C in components was lower case.
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import "./App.css";
import pointsOfInterest from "./assets/data/locationManifest.json";
import Sidebar from "./Components/Sidebar/Sidebar";

import UserContextProvider from "./contexts/UserContext/UserContext";

const envVariables = process.env;
// var strTestObject = 'Found and working';

mapboxgl.accessToken = envVariables.REACT_APP_MAPBOX_TOKEN;

function App() {
  return (
    <>
      <UserContextProvider>
        <MainMap pointsOfInterest={pointsOfInterest}></MainMap>
        <Sidebar pointsOfInterest={pointsOfInterest}></Sidebar>
      </UserContextProvider>
    </>
  );
}

export default App;
