import React, { useState } from "react";
import SearchFeature from "../SearchFeature/SearchFeature";
import { FaInfoCircle, FaSearchLocation, FaBars } from "react-icons/fa";

import "./Sidebar.css";

const Sidebar = ({ pointsOfInterest }) => {

  const [isOpen, setIsOpen] = useState(true);
  const [isDisplayOpen, setIsDisplayOpen] = useState(false);
  const [selected, setSelected] = useState("Search");

  const toggle = (id) => {
    setIsOpen(!isOpen);
  };

  const toggleWindow = (e) => {

    const sidebarWindow = document.querySelector(".sidebar-window");

    if (sidebarWindow.classList.contains('hidden')) {

      setIsDisplayOpen(true);
      setIsOpen(true);

      sidebarWindow.classList.remove('hidden');

    } else {
      setIsDisplayOpen(false);
    }

    const selectedTab = e.currentTarget.id;
    setSelected(selectedTab);

  };

  const menuItems = [
    {
      name: "Search",
      icon: <FaSearchLocation />,
      page: (
        <SearchFeature
          pointsOfInterest={pointsOfInterest}
          isOpen={isOpen}
          isDisplayOpen={isDisplayOpen}
        />
      ),
    },
    {
      name: "About Explore SC",
      icon: <FaInfoCircle />,
      page: (
        <div style={{paddingTop: '50px', marginLeft: '10vw', marginRight: '10vw'}}>
          <div>
          <p>
            ExploreSC is a project of the University of South Carolina's Institute for Southern Studies. Informative links provided within the app are to the South Carolina Encyclopedia, a collaborative effort of the Institute for Southern Studies, the University of South Carolina Press, and South Carolina Humanities. Most of the photographs and location information for the monuments and markers listed in ExploreSC are taken from the Historical Markers Database, a crowd-sourced collection of historical sites from across the US and beyond.
          </p>
          <p>
            Have we missed a historical marker or monument in your area? Email us at exploresc@mailbox.sc.edu to let us know!
          </p>
          </div>
          <img src="logo-white.png" alt="University of South Carolina's Logo" style={{height: "100px"}}/>
        </div>
      ),
    },
  ];

  return (
    <div
      className={`sidebar-container ${
        !isDisplayOpen || !isOpen ? "hidden" : ""
      }`}
    >
      <div className="sidebar">
        <div className="top_section">
          <div className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItems.map((item, idx) => {
          return (
            <div
              style={
                ({ visibility: isOpen ? "visible" : "hidden" },
                { opacity: isOpen ? 1 : 0 })
              }
              className="sidebar-item"
              id={item.name}
              key={idx}
              title={item.name}
              onClick={(e) => toggleWindow(e)}
            >
              <div className="sidebar-icon">{item.icon}</div>
            </div>
          );
        })}
      </div>
      <div
        className={`sidebar-window ${
          !isDisplayOpen || !isOpen ? "hidden" : ""
        }`}
        style={
          ({ visibility: isDisplayOpen && isOpen ? "visible" : "hidden" },
          { opacity: isDisplayOpen && isOpen ? 1 : 0 })
        }
      >
        {selected
          ? menuItems.find((item) => item.name === selected).page
          : null}
      </div>
    </div>
  );
};

export default Sidebar;
