import React, { useContext, useState } from "react";
// import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { MapContainer, TileLayer, ZoomControl, useMap } from "react-leaflet";
import LayersControlGroup from "../LayersControlGroup/LayersControlGroup";
import "leaflet/dist/leaflet.css";
import "./MainMap.css";
import Sidebar from "../Sidebar/Sidebar";
import CurrentLocation from "../CurrentLocation/CurrentLocation";
import UpdateLocation from "../UpdateLocation/UpdateLocation";

import { UserContext } from "../../contexts/UserContext/UserContext";


const MainMap = (props) => {
  const { position } = useContext(UserContext);

  const { pointsOfInterest } = props;
  // const map = useMap();

  return (
    <>
      <MapContainer
        style={{ height: "100vh", width: "100vw" }}
        zoom={15}
        zoomControl={false}
        center={position || [33.66155, -78.9379]}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <UpdateLocation pointsOfInterest={pointsOfInterest} />
        <CurrentLocation />
        <LayersControlGroup pointsOfInterest={pointsOfInterest} />
        <ZoomControl position="bottomright" />
      </MapContainer>
    </>
  );
};

export default MainMap;
