import React from "react";
import { Popup } from "react-leaflet";
// import "./POIPopUp.css";


const splitUrls = (urls) => {

  const urlArray = urls.split(",");
  const links = urlArray.map((url) => {

    const splitUrl = url.split("/"); 
    const nameURL = splitUrl[splitUrl.length - 2];

    if (nameURL != null) {

      const title = nameURL
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
      
      return `<a href="${url}" target="_blank">${title}</a>`;

    } else {
      return null;
    }

  });
  return links.join(" | ");
};

const POIPopUp = (props) => {
  const { position, poi } = props;
  const { Name, Description_full, Links, Images } = poi.properties;
  const imgAttr = poi.properties["Image Attribution"];
  const txtAttr = poi.properties["Text Attribution"];


  return (
    <Popup position={position} >
      <h3>{Name}</h3>
      <div style={{
          maxHeight: '150px',
          overflow: 'auto',
          borderStyle: 'solid',
          borderWidth: '1px 0',
          borderColor: 'lightgrey'
      }}>
        {Description_full}
      </div>
      <div style={{marginTop: '15px'}}>
        <div style={{marginBottom: '5px'}}>{txtAttr}</div>
        <div style={{
          overflow: 'auto',
          maxHeight: '225px',
          borderStyle: 'solid',
          borderWidth: '1px 0',
          borderColor: 'lightgrey'
        }}>
          <img src={Images} alt={`${Name} Scenic View`} style={{width: '100%'}}></img>
        </div>
        <div style={{marginTop: '5px'}}>{imgAttr}</div>
        <div style={{marginTop: '5px'}}><b>From the SC Encyclopedia:</b></div>
        <div dangerouslySetInnerHTML={{ __html: splitUrls(Links) }} />
      </div>
      <div style={{marginTop: '10px'}}>
        <a href={'https://maps.google.com/?q=' + position} target="_blank" rel="noreferrer" >View on Google Maps</a>
      </div>
    </Popup>
  );
};

export default POIPopUp;
